import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useAuth } from 'react-oidc-context';
import { urls } from '@/utils/urls';
import { ReadyToJoinButton, ReadyToJoinContent, ReadyToJoinHeading, ReadyToJoinText, ReadyToJoinWrapper } from './ReadyToJoin.styles';
const url = `${process.env.NEXT_PUBLIC_PS_CDN_URL}/clip.webm`;
export interface ReadyToJoinProps {
  className?: string;
  withoutOffset?: boolean;
}
export const ReadyToJoin = ({
  className,
  withoutOffset = false
}: ReadyToJoinProps) => {
  const {
    t
  } = useTranslation('trustedUs');
  const {
    isAuthenticated,
    signinRedirect
  } = useAuth();
  const router = useRouter();
  const handleButtonClick = () => isAuthenticated ? router.push(urls.exchanger) : signinRedirect();
  return <ReadyToJoinWrapper className={className} $withoutOffset={withoutOffset} data-sentry-element="ReadyToJoinWrapper" data-sentry-component="ReadyToJoin" data-sentry-source-file="ReadyToJoin.tsx">
      <video autoPlay loop muted preload="none" style={{
      width: '100%',
      borderRadius: '6px'
    }}>
        <source src={url} type="video/webm" />
      </video>
      <ReadyToJoinContent data-sentry-element="ReadyToJoinContent" data-sentry-source-file="ReadyToJoin.tsx">
        <ReadyToJoinHeading data-sentry-element="ReadyToJoinHeading" data-sentry-source-file="ReadyToJoin.tsx">{t('readyToJoin.title')}</ReadyToJoinHeading>
        <ReadyToJoinText data-sentry-element="ReadyToJoinText" data-sentry-source-file="ReadyToJoin.tsx">{t('readyToJoin.textPartOne')}</ReadyToJoinText>
        <ReadyToJoinText data-sentry-element="ReadyToJoinText" data-sentry-source-file="ReadyToJoin.tsx">{t('readyToJoin.textPartTwo')}</ReadyToJoinText>
        <ReadyToJoinButton onClick={handleButtonClick} rwdSizes={{
        xs: 'md',
        xl: 'lg'
      }} data-sentry-element="ReadyToJoinButton" data-sentry-source-file="ReadyToJoin.tsx">
          {t('readyToJoin.button')}
        </ReadyToJoinButton>
      </ReadyToJoinContent>
    </ReadyToJoinWrapper>;
};