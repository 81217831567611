import { AnimationOptions, animate, motion, useMotionValue, useTransform } from 'motion/react';
import { useEffect } from 'react';
interface AnimatedNumberProps {
  value: number;
  formatter?: (value: number) => string;
  className?: string;
  options?: AnimationOptions;
  dataTestId?: string;
}
const defaultFormatter = (val: number) => Math.round(val).toString();
export const AnimatedNumber = ({
  dataTestId,
  value,
  formatter = defaultFormatter,
  className,
  options = {
    type: 'tween'
  }
}: AnimatedNumberProps) => {
  const count = useMotionValue(value);
  const rounded = useTransform(count, formatter);
  useEffect(() => {
    // @ts-ignore
    const controls = animate(count, value, options);
    return controls.stop;
  }, [value, count, options]);
  return <motion.span data-testid={dataTestId} className={className} suppressHydrationWarning data-sentry-element="motion.span" data-sentry-component="AnimatedNumber" data-sentry-source-file="AnimatedNumber.tsx">
      {rounded}
    </motion.span>;
};