import styled from 'styled-components'

import { Button } from '@/components/Button'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { goldman, lato } from '@/utils/fonts'

export const ReadyToJoinWrapper = styled.section<{
  $withoutOffset: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: ${({ $withoutOffset }) => ($withoutOffset ? '0' : '40px')};
    display: grid;
    grid-template-columns: 4fr 3fr;
    column-gap: 80px;
  }

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    column-gap: 120px;
  }
`

export const ReadyToJoinContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    max-width: 75%;
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    align-items: flex-start;
    max-width: unset;
  }
`

export const ReadyToJoinHeading = styled.h2`
  font-family: ${goldman.style.fontFamily};
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
  text-wrap: balance;
  text-align: center;
  color: ${WHITE};

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 30px;
    line-height: 35px;
    text-align: left;
  }
`

export const ReadyToJoinText = styled.p`
  font-family: ${lato.style.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-wrap: balance;
  text-align: center;
  color: ${GRAY.x400};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }
`

export const ReadyToJoinButton = styled(Button)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    width: fit-content;
    min-width: 260px;
  }
`
