import { useTranslation } from 'next-i18next';
import EternalFireLogoImage from 'public/images/trusted-by/partners/eternal-fire-logo.png';
import EternalFire from 'public/images/trusted-by/partners/eternalfire.webp';
import MongolzLogoImage from 'public/images/trusted-by/partners/mongolz-logo.png';
import Mongolz from 'public/images/trusted-by/partners/mongolz.webp';
import { DecorationalBlock1, DecorationalBlock2, LogoWrapper, LogosContainer, MobileGradient, OfficialPartnersContainer, OfficialPartnersContent, OfficialPartnersHeading, OfficialPartnersLogo, OfficialPartnersText, OfficialPartnersWrapper, StrippedDecoration, TeamImage } from '@/domains/trustedUs/OfficialPartners/OfficialPartners.styles';
export interface OfficialPartnersProps {
  className?: string;
  withoutDecoration?: boolean;
}
const OfficialPartners = ({
  className,
  withoutDecoration = false
}: OfficialPartnersProps) => {
  const {
    t
  } = useTranslation('trustedUs');
  return <OfficialPartnersWrapper className={className} $withoutDecoration={withoutDecoration} data-sentry-element="OfficialPartnersWrapper" data-sentry-component="OfficialPartners" data-sentry-source-file="OfficialPartners.tsx">
      {!withoutDecoration && <StrippedDecoration />}
      <OfficialPartnersContainer data-sentry-element="OfficialPartnersContainer" data-sentry-source-file="OfficialPartners.tsx">
        <TeamImage src={Mongolz} alt="mongolz team" data-sentry-element="TeamImage" data-sentry-source-file="OfficialPartners.tsx" />
        <OfficialPartnersContent data-sentry-element="OfficialPartnersContent" data-sentry-source-file="OfficialPartners.tsx">
          <LogosContainer data-sentry-element="LogosContainer" data-sentry-source-file="OfficialPartners.tsx">
            <LogoWrapper data-sentry-element="LogoWrapper" data-sentry-source-file="OfficialPartners.tsx">
              <OfficialPartnersLogo src={MongolzLogoImage} width={89} height={48} alt="mongolz logo" data-sentry-element="OfficialPartnersLogo" data-sentry-source-file="OfficialPartners.tsx" />
            </LogoWrapper>
            <LogoWrapper data-sentry-element="LogoWrapper" data-sentry-source-file="OfficialPartners.tsx">
              <OfficialPartnersLogo src={EternalFireLogoImage} width={89} height={48} alt="eternal fire logo" data-sentry-element="OfficialPartnersLogo" data-sentry-source-file="OfficialPartners.tsx" />
            </LogoWrapper>
          </LogosContainer>
          <OfficialPartnersHeading data-sentry-element="OfficialPartnersHeading" data-sentry-source-file="OfficialPartners.tsx">
            {t('officialPartner.title')}
          </OfficialPartnersHeading>
          <OfficialPartnersText data-sentry-element="OfficialPartnersText" data-sentry-source-file="OfficialPartners.tsx">
            {t('officialPartner.description')}
          </OfficialPartnersText>
        </OfficialPartnersContent>
        <TeamImage src={EternalFire} alt="eternal fire team" data-sentry-element="TeamImage" data-sentry-source-file="OfficialPartners.tsx" />
        <DecorationalBlock1 data-sentry-element="DecorationalBlock1" data-sentry-source-file="OfficialPartners.tsx" />
        <DecorationalBlock2 data-sentry-element="DecorationalBlock2" data-sentry-source-file="OfficialPartners.tsx" />
        <MobileGradient data-sentry-element="MobileGradient" data-sentry-source-file="OfficialPartners.tsx" />
      </OfficialPartnersContainer>
    </OfficialPartnersWrapper>;
};
export default OfficialPartners;